@font-face {
  font-family: "Poppins";
  src: local("PoppinsRegular"), url("./assets/fonts/Poppins-Regular.ttf") format("truetype");
  font-weight: 400;
}

.panel-con {
  overflow-y: auto;
}

::-webkit-scrollbar {
  width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #ffffff;
  /* margin-left: ; */
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.menu-tooltip {
  display: inline-block;
  position: fixed;
  padding: 5px 15px;
  border-radius: 3px;
  left: 90px;
  background: #212a30;
  opacity: 0;
  visibility: hidden;
  font-size: 13px;
  letter-spacing: 0.5px;

  &:before {
    content: "";
    display: block;
    position: absolute;
    left: -4px;
    top: 11.5px;
    transform: rotate(45deg);
    width: 10px;
    height: 10px;
    background-color: inherit;
  }
}

.layout-note {
  height: 38px;
  width: 100%;
  background: #ebebeb;
  margin: -24px;
  padding: 10px;
  line-height: 20px;
  position: fixed;
  bottom: 22px;
  z-index: 100;
}

.ql-editor p,
.ql-editor b,
.ql-editor ol,
.ql-editor ul,
.ql-editor pre,
.ql-editor blockquote,
.ql-editor h1,
.ql-editor h2,
.ql-editor h3,
.ql-editor h4,
.ql-editor h5,
.ql-editor h6 {
  font-family: "Poppins" !important;
  font-size: 0.9285714285714286rem !important;
}

.display-flex {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
}

.Grid-container {
  margin-bottom: 15px;

  .Grid-item {
    display: flex;
    align-items: center;

    .Grid-item-label {
      font-weight: 300;
      letter-spacing: 0.04rem;
    }

    .Grid-item-required {
      color: #d32f2f;
      padding-left: 5px;
      font-weight: 600;
      font-size: 22px;
    }
  }
}
